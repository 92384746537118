import React, { useEffect, useState } from 'react'
import { client } from '../prismic-configuration'

// COMPONENTS
import { Header, Footer, Loader } from '../components'
import { Head, Description, Content } from '../components/Project'

export const Project = (props) => {
    const [doc, setDocData] = useState(null)
    const [notFound, setNotFound] = useState(true)
    const uid = window.location.pathname.replace('/projects/', '')

    useEffect(() => {
        const fetchData = async () => {
            const result = await client.getByUID('project', uid)

            if (result) {
                setDocData(result)
                setNotFound(false)
            } else {
                setNotFound(true)
                console.log('Could not find page or document.')
            }
        }
        fetchData()
    }, [uid])

    if (!notFound) {
        return (
            <div className="project responsive">
                <Header />
                <Head doc={doc} />
                <Description doc={doc} />
                <Content doc={doc} />
                <Footer />
            </div>
        )
    } else {
        return <Loader />
    }
}
