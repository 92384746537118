import React from 'react'
import { RichText } from 'prismic-reactjs'

export const Description = (props) => {
    // Roles loop
    const roles = []
    for (let i = 0; i < props.doc.data.project_roles.length; i++) {
        roles.push(
            <li key={`project_role--${i}`}>
                {RichText.asText(props.doc.data.project_roles[i].role)}
            </li>
        )
    }

    // Description loop
    const paragraphs = []
    for (let i = 0; i < props.doc.data.project_description.length; i++) {
        paragraphs.push(
            <li
                className="project__description__text"
                key={`project_description_paragraph--${i}`}
            >
                {RichText.asText(
                    props.doc.data.project_description[i].paragraph
                )}
            </li>
        )
    }

    // Images loop
    const images = []
    for (let i = 0; i < props.doc.data.project_description_images.length; i++) {
        images.push(
            <div
                className="project__description__img--wrapper"
                key={`project_description_image--${i}`}
            >
                <img
                    src={props.doc.data.project_description_images[i].image.url}
                    alt={props.doc.data.project_description_images[i].image.alt}
                    className="project__description__img"
                />
            </div>
        )
    }

    return (
        <div className="project__description responsive__container">
            <div className="project__description__col project__description__col--left">
                <h3 className="project__description__role-title font__subtitle font__pink">
                    What was my role?
                </h3>
                <ul className="project__description__role font__text">
                    {roles}
                </ul>
            </div>

            <div className="project__description__col project__description__col--right">
                <div className="project__description__row">
                    <ul className="project__description__texts font__text">
                        {paragraphs}
                    </ul>
                </div>

                <div className="project__description__polaroids project__description__row">
                    {images}
                </div>
            </div>
        </div>
    )
}
