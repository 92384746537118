import React from 'react'

export const Polaroids = (props) => {
    const borderTop = props.doc.primary.border_top
    const borderBot = props.doc.primary.border_bot

    const photos = []
    for (let i = 0; i < props.doc.items.length; i++) {
        photos.push(
            <div
                className="project__polaroids__img--wrapper"
                key={`polaroids_image--${i}`}
            >
                <img
                    src={props.doc.items[i].polaroids_image.url}
                    alt={props.doc.items[i].polaroids_image.alt}
                    className="project__polaroids__img"
                />
            </div>
        )
    }

    return (
        <div
            className={`
                    project__polaroids responsive__container border
                    ${borderTop ? 'border-top' : ''}
                    ${borderBot ? 'border-bot' : ''}
                `}
        >
            {photos}
        </div>
    )
}
