import React from 'react'
import { RichText } from 'prismic-reactjs'

export const Presentation = (props) => {
    const borderTop = props.doc.primary.border_top
    const borderBot = props.doc.primary.border_bot

    const texts = []
    for (let i = 0; i < props.doc.items.length; i++) {
        texts.push(
            <li
                className="project__presentation__text"
                key={`presentation__text--${i}`}
            >
                {RichText.asText(props.doc.items[i].presentation_paragraph)}
            </li>
        )
    }

    return (
        <div
            className={`
                project__presentation responsive__container border
                ${borderTop ? 'border-top' : ''}
                ${borderBot ? 'border-bot' : ''}
            `}
        >
            <div className="project__presentation__col project__presentation__col--left">
                <h3 className="project__presentation__title font__title">
                    {RichText.asText(props.doc.primary.presentation_title)}
                </h3>
            </div>
            <div className="project__presentation__col project__presentation__col--right">
                <ul className="project__presentation__texts font__text">
                    {texts}
                </ul>
            </div>
        </div>
    )
}
