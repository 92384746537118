import React from 'react'

export const Loader = () => {
    return (
        <div className="loader">
            <div className="loader__overlay"></div>
            <div className="loader__container">
                <div className="loader__circle"></div>
                <p className="loader__text font__title">Loading...</p>
            </div>
        </div>
    )
}
