import React from 'react'
import { RichText } from 'prismic-reactjs'

export const Punchline = (props) => {
    const borderTop = props.doc.primary.border_top
    const borderBot = props.doc.primary.border_bot

    return (
        <div
            className={`
                project__punchline responsive__container border
                ${borderTop ? 'border-top' : ''}
                ${borderBot ? 'border-bot' : ''}
            `}
        >
            <h3 className="project__punchline__text font__subtitle">
                {RichText.asText(props.doc.primary.punchline_sentence)}
            </h3>
        </div>
    )
}
