import React from 'react'
import { RichText } from 'prismic-reactjs'

export const Head = (props) => {
    return (
        <div className="project__head responsive__container">
            <img
                src={props.doc.data.project_logo.url}
                alt={props.doc.data.project_logo.alt}
                className="project__head__img"
            />
            <h1 className="project__head__title font__title">
                {RichText.asText(props.doc.data.project_subtitle)}
            </h1>
        </div>
    )
}
