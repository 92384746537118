import React from 'react'
import { RichText } from 'prismic-reactjs'

export const Thanks = (props) => {
    const borderTop = props.doc.primary.border_top
    const borderBot = props.doc.primary.border_bot

    const list = []
    for (let i=0; i<props.doc.items.length; i++) {
        list.push(
            <span className="font__text" key={`thanks_name--${i}`}>
                {RichText.asText(props.doc.items[i].name)}
                {i === props.doc.items.length - 1 ? '...' : ', '}
            </span>
        )
    }

    return (
        <div
            className={`
                project__thanks responsive__container border
                ${borderTop ? 'border-top' : ''}
                ${borderBot ? 'border-bot' : ''}
            `}
        >
            <div className="project__thanks__col project__thanks__col--left">
                <div className="project__thanks__title font__subtitle font__red">
                    Thanks to :
                </div>
                <div className="project__thanks__text font__text">{list}</div>
            </div>
            <div className="project__thanks__col project__thanks__col--right">
                <div className="project__thanks__title font__subtitle font__red">
                    {RichText.asText(props.doc.primary.social_title)}
                </div>

                <ul className="project__thanks__list">
                    {props.doc.primary.instagram.url ? (
                        <li className="project__thanks__list__el font__text">
                            <a
                                href={props.doc.primary.instagram.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Instagram
                            </a>
                        </li>
                    ) : null}
                    {props.doc.primary.facebook.url ? (
                        <li className="project__thanks__list__el font__text">
                            <a
                                href={props.doc.primary.facebook.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Facebook
                            </a>
                        </li>
                    ) : null}
                    {props.doc.primary.twitter.url ? (
                        <li className="project__thanks__list__el font__text">
                            <a
                                href={props.doc.primary.twitter.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >Twitter</a>
                        </li>
                    ) : null}
                </ul>
            </div>
        </div>
    )
}
