import React from 'react'
import { Presentation, Polaroids, Punchline, ColumnParagraph, Video, Module, Thanks } from './index'

export const Content = (props) => {
    const content = []

    for (let i = 0; i < props.doc.data.body.length; i++) {
        switch (props.doc.data.body[i].slice_type) {
            case 'presentation':
                content.push(
                    <Presentation
                        doc={props.doc.data.body[i]}
                        key={`content--${i}`}
                    />
                )
                break

            case 'polaroids':
                content.push(
                    <Polaroids
                        doc={props.doc.data.body[i]}
                        key={`content--${i}`}
                    />
                )
                break
            case 'punchline':
                content.push(
                    <Punchline
                        doc={props.doc.data.body[i]}
                        key={`content--${i}`}
                    />
                )
                break
            case 'column_paragraph':
                content.push(
                    <ColumnParagraph
                        doc={props.doc.data.body[i]}
                        key={`content--${i}`}
                    />
                )
                break
            case 'video':
                content.push(
                    <Video doc={props.doc.data.body[i]} key={`content--${i}`} />
                )
                break
            case 'module':
                content.push(
                    <Module
                        doc={props.doc.data.body[i]}
                        key={`content--${i}`}
                    />
                )
                break
            case 'thanks':
                content.push(
                    <Thanks
                        doc={props.doc.data.body[i]}
                        key={`content--${i}`}
                    />
                )
                break

            default:
                break
        }
    }

    return <>{content}</>
}
