import React from 'react'
import { RichText } from 'prismic-reactjs'

export const Module = (props) => {
    const borderTop = props.doc.primary.border_top
    const borderBot = props.doc.primary.border_bot

    return (
        <div
            className={`
            project__module responsive__container border
                ${borderTop ? 'border-top' : ''}
                ${borderBot ? 'border-bot' : ''}
            `}
        >
            <div className="project__module__col project__module__col--left">
                <div className="project__module__title font__subtitle font__red">
                    {RichText.asText(props.doc.primary.module_title)}
                </div>
                <div className="project__module__text font__text">
                    {RichText.asText(props.doc.primary.module_text)}
                </div>
            </div>
            <div className="project__module__col project__module__col--right">
                <div className="project__module__subtitle font__title">
                    {RichText.asText(props.doc.primary.module_subtitle)}
                </div>
            </div>
        </div>
    )
}
