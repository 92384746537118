import React from 'react'
import { RichText } from 'prismic-reactjs'

export const Video = (props) => {
    const borderTop = props.doc.primary.border_top
    const borderBot = props.doc.primary.border_bot

    return (
        <div
            className={`
                project__video responsive__container border
                ${borderTop ? 'border-top' : ''}
                ${borderBot ? 'border-bot' : ''}
            `}
        >
            <div className="project__video__content">
                <div className="project__video__col project__video__col--left">
                    <video
                        className="project__video__player"
                        src={props.doc.primary.video_link.url}
                        autoPlay={true}
                        muted={true}
                        loop={true}
                    ></video>
                </div>
                <div className="project__video__col project__video__col--right">
                    <div className="project__video__description font__subtitle font__grey">
                        {RichText.asText(props.doc.primary.video_description)}
                    </div>
                </div>
            </div>
        </div>
    )
}
