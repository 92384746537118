import React, { useEffect, useState } from 'react'
import { client } from '../prismic-configuration'
import { RichText } from 'prismic-reactjs'
// import { handleLoader } from '../scripts'
import contactImage from '../assets/images/Contact.png'
import mailImage from '../assets/images/mail.png'
import twitterImage from '../assets/images/twitter.png'
import codepenImage from '../assets/images/codepen.png'
import githubImage from '../assets/images/github.png'
import linkedinImage from '../assets/images/linkedin.png'

// COMPONENTS
import { Header, Footer, Advert, Loader } from '../components'

export const Contact = () => {
    const [doc, setDocData] = useState(null)
    const [notFound, setNotFound] = useState(true)
    const uid = 'contact'

    useEffect(() => {
        const fetchData = async () => {
            const result = await client.getByUID(uid, uid)

            if (result) {
                setDocData(result)
                setNotFound(false)
                // setTimeout(handleLoader(), 2000)
            } else {
                setNotFound(true)
                console.log('Could not find page or document.')
            }
        }
        fetchData()
    }, [uid])

    if (!notFound) {
        return (
            <div className="contact responsive">
                <Header />
                <div className="contact__content responsive__container">
                    <Advert
                        title="Let’s get in touch !"
                        subtitle="And create something great together !"
                        image={contactImage}
                    />
                    <div className="contact__media">
                        <a
                            href="mailto:alexandrechabeau.pro@gmail.com"
                            target="_blank"
                            className="contact__media__el"
                        >
                            <img
                                src={mailImage}
                                alt=""
                                className="contact__media__img contact__media__img"
                            />
                        </a>
                        <a
                            href="https://twitter.com/s0wcy"
                            target="_blank"
                            className="contact__media__el"
                        >
                            <img
                                src={twitterImage}
                                alt=""
                                className="contact__media__img contact__media__img"
                            />
                        </a>
                        <a
                            href="https://codepen.io/saucy_"
                            target="_blank"
                            className="contact__media__el"
                        >
                            <img
                                src={codepenImage}
                                alt=""
                                className="contact__media__img contact__media__img"
                            />
                        </a>
                        <a
                            href="https://github.com/s0wcy"
                            target="_blank"
                            className="contact__media__el"
                        >
                            <img
                                src={githubImage}
                                alt=""
                                className="contact__media__img contact__media__img"
                            />
                        </a>
                        <a
                            href="https://www.linkedin.com/in/0xAlex/"
                            target="_blank"
                            className="contact__media__el"
                        >
                            <img
                                src={linkedinImage}
                                alt=""
                                className="contact__media__img contact__media__img"
                            />
                        </a>
                    </div>
                    <div className="contact__sections">
                        <div className="contact__section">
                            {/* <p className="contact__section__text font__text">
                                Just letting you know that I’m looking for ~6
                                months worldwide part time job, between june 2021
                                and february 2022.
                            </p>
                            <p className="contact__section__text font__text">
                                I do not exclude remote work due to corona
                                virus, even if I would prefer to meet you in
                                person.
                            </p>{' '} */}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    } else {
        return <Loader />
    }
}
