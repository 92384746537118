import React, { useEffect, useState, useRef } from 'react'
// import SplitTextJS from 'split-text-js'

export const Eye = () => {
    const [x, setX] = useState()
    const [y, setY] = useState()
    const hitboxRef = useRef()

    useEffect(() => {
        const update = (_e) => {
            setX(_e.x)
            setY(_e.y)
        }
        window.addEventListener('mousemove', update)
        window.addEventListener('touchmove', update)

        return () => {
            window.removeEventListener('mousemove', update)
            window.removeEventListener('touchmove', update)
        }
    }, [setX, setY])

    const handleHover = (_e) => {
    }

    return (
        <div className="eye responsive__container">

            <svg width="100%" height="100%" viewBox="0 0 702 469">
                {/* UPPER TEXT */}
                <path
                    id="uppertext"
                    fill="transparent"
                    d="M1 168.192C1 168.192 128.273 1.03955 351 1.03955C573.727 1.03955 701 168.192 701 168.192"
                />
                <text
                    width="100%"
                    style={{
                        transform: `
                            translate3d(
                                ${(x - (window.innerWidth / 2)) / window.innerWidth * 20}px,
                                ${(y - (window.innerHeight / 2)) / window.innerHeight * 20}px,
                                0
                            )
                        `
                    }}
                    className="eye__uppertext__text"
                >
                    <textPath
                        style={{ transform: 'translate3d(0,0,0)' }}
                        alignmentBaseline="top"
                        xlinkHref="#uppertext"
                        startOffset='16.5%'
                    >
                        The Wonderfull
                    </textPath>
                </text>

                {/* MIDDLE TEXT */}
                <path
                    id="middletext"
                    fill="transparent"
                    d="M204.36 169.573C204.36 169.573 254.162 96.3578 353.767 96.3578C453.372 96.3578 500.407 169.573 500.407 169.573"
                />
                <text
                    width="100%"
                    style={{
                        transform: `
                            translate3d(
                                ${(x - (window.innerWidth / 2)) / window.innerWidth * 10}px,
                                ${(y - (window.innerHeight / 2)) / window.innerHeight * 10}px,
                                0
                            )
                        `
                    }}
                    className="eye__middletext__text"
                >
                    <textPath
                        style={{ transform: 'translate3d(0,0,0)' }}
                        alignmentBaseline="top"
                        xlinkHref="#middletext"
                        startOffset='20%'
                    >
                        Power Of
                    </textPath>
                </text>

                {/* LOWER TEXT */}
                <path
                    id="lowertext"
                    fill="transparent"
                    d="M20.3676 318.767C20.3676 318.767 117.206 467.961 351 467.961C584.794 467.961 681.632 318.767 681.632 318.767"
                />
                <text
                    width="100%"
                    style={{
                        perspective: 100,
                        transformOrigin: 'center center',
                        transform: `
                            translate3d(
                                ${(x - (window.innerWidth / 2)) / window.innerWidth * 20}px,
                                ${(y - (window.innerHeight / 2)) / window.innerHeight * 20}px,
                                0
                            )
                        `
                    }}
                    className="eye__lowertext__text"
                >
                    <textPath
                        style={{ transform: 'translate3d(0,0,0)' }}
                        alignmentBaseline="top"
                        xlinkHref="#lowertext"
                        startOffset='12.5%'
                    >
                        World Wide Web
                    </textPath>
                </text>

                {/* EYE OUT */}
                <path
                    style={{
                        transform: `
                            translate3d(
                                ${(x - (window.innerWidth / 2)) / window.innerWidth * 5}px,
                                ${(y - (window.innerHeight / 2)) / window.innerHeight * 5}px,
                                0
                            )
                        `
                    }}
                    d="M467.972 248.314C467.972 312.82 415.605 365.117 351 365.117C286.395 365.117 234.028 312.82 234.028 248.314C234.028 183.809 286.395 131.512 351 131.512C415.605 131.512 467.972 183.809 467.972 248.314Z"
                    stroke="black"
                    fill="transparent"
                    strokeWidth="10"
                />

                {/* EYE IN */}
                <path
                    style={{
                        transform: `
                            translate3d(
                                ${(x - (window.innerWidth / 2)) / window.innerWidth * 50}px,
                                ${(y - (window.innerHeight / 2)) / window.innerHeight * 50}px,
                                0
                            )
                        `
                    }}
                    d="M430.474 248.123C430.474 291.266 395.449 326.245 352.237 326.245C309.025 326.245 274 291.266 274 248.123C274 230.244 280.012 213.774 290.128 200.609C290.331 200.405 290.668 200.229 291.378 200.238C292.184 200.248 293.279 200.505 294.662 201.051C297.423 202.142 300.747 204.143 304.168 206.343C305.094 206.939 306.03 207.551 306.959 208.158C309.373 209.738 311.737 211.284 313.747 212.421C315.138 213.207 316.499 213.881 317.693 214.237C318.713 214.54 320.458 214.871 321.763 213.686C323.081 212.49 322.865 210.724 322.637 209.723C322.369 208.542 321.796 207.183 321.116 205.793C320.097 203.709 318.67 201.233 317.213 198.704C316.72 197.85 316.225 196.99 315.74 196.137C313.775 192.678 311.973 189.306 310.996 186.515C310.507 185.116 310.276 184.001 310.274 183.173C310.273 182.508 310.415 182.177 310.558 181.996C322.623 174.397 336.913 170 352.237 170C395.449 170 430.474 204.979 430.474 248.123Z"
                    fill="black"
                />
            </svg>

            <div className="eye__hitbox" ref={hitboxRef}></div>

        </div>
    )
}