import React, { useEffect, useState } from 'react'
import { client } from '../prismic-configuration'
import { RichText } from 'prismic-reactjs'
// import { handleLoader } from '../scripts'
import aboutImage from '../assets/images/About.png'

// COMPONENTS
import { Header, Footer, Advert, Loader } from '../components'

export const About = () => {
    const [doc, setDocData] = useState(null)
    const [notFound, setNotFound] = useState(true)
    const uid = 'about'

    useEffect(() => {
        const fetchData = async () => {
            const result = await client.getByUID(uid, uid)

            if (result) {
                setDocData(result)
                setNotFound(false)
                // setTimeout(handleLoader(), 2000)
            } else {
                setNotFound(true)
                console.log('Could not find page or document.')
            }
        }
        fetchData()
    }, [uid])

    if (!notFound) {
        return (
            <div className="about responsive">
                <Header />
                <div className="about__content responsive__container">
                    <Advert
                        title="So you want to know more ?"
                        subtitle="Enter my weird mind now !"
                        image={aboutImage}
                    />
                    <div className="about__sections">
                        <div className="about__section">
                            <h3 className="about__section__title font__title">
                                WHO AM I{' '}
                                <span className="font__special">?</span>
                            </h3>
                            {doc?.data?.body[0]?.items?.map(
                                (element, index) => (
                                    <p
                                        className="about__section__text font__text"
                                        key={`paragraphs-0-${index}`}
                                    >
                                        {element?.text[0].text}
                                    </p>
                                )
                            )}
                        </div>
                        <div className="about__section">
                            <h3 className="about__section__title font__title">
                                Any Skills{' '}
                                <span className="font__special">?</span>
                            </h3>
                            {doc?.data?.body[1]?.items?.map(
                                (element, index) => (
                                    <p
                                        className="about__section__text font__text"
                                        key={`paragraphs-1-${index}`}
                                    >
                                        {element?.text[0].text}
                                    </p>
                                )
                            )}
                        </div>
                        <div className="about__section">
                            <h3 className="about__section__title font__title">
                                My tools{' '}
                                <span className="font__special">?</span>
                            </h3>
                            {doc?.data?.body[2]?.items?.map(
                                (element, index) => {
                                    let formattedText =
                                        element?.text[0].text.replace(
                                            /\n/g,
                                            '\n'
                                        )
                                    let splitText = formattedText.split('\n')
                                    return (
                                        <ul
                                            className="about__section__text font__text"
                                            key={`paragraphs-0-${index}`}
                                        >
                                            {splitText.map((element, index) => (
                                                <li
                                                    className="about__section__list__el"
                                                    key={`paragraph-2-${index}`}
                                                >
                                                    {element}
                                                </li>
                                            ))}
                                        </ul>
                                    )
                                }
                            )}
                            <p className="about__section__text font__text">
                                That's a non exhaustive list. I can use or learn
                                anything if it helps reaching my goals.
                            </p>
                        </div>
                    </div>
                    {/* {Sections(doc.data.body)} */}
                </div>
                <Footer />
            </div>
        )
    } else {
        return <Loader />
    }
}

const Sections = (_sections) => {
    const sections = []

    for (let i = 0; i < _sections.length; i++) {
        const texts = SectionTexts(_sections[i].items)

        sections.push(
            <div className="about__section">
                <h3 className="about__section__title font__title">
                    {RichText.asText(_sections[i].primary.title)}
                </h3>
                {texts}
            </div>
        )
    }

    return sections
}

const SectionTexts = (_texts) => {
    const texts = []

    for (let i = 0; i < _texts.length; i++) {
        texts.push(
            <p className="about__section__text font__text">
                {RichText.asText(_texts[i].text)}
            </p>
        )
    }

    return <div className="about__section__texts">{texts}</div>
}
