import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Advert } from '../Advert'
import menuImage from '../../assets/images/Menu.png'

export const Header = () => {
    let header = React.useRef()
    let button = React.useRef()

    const handleClick = () => {
        if(!header.current.classList.contains("header--open")) {
            header.current.classList.add('header--open')
            button.current.innerHTML = "X"
        }
        else {
            header.current.classList.remove('header--open')
            button.current.innerHTML = "O"
        }
    }

    return (
        <div className="header responsive" ref={header}>
            <div className="header__container responsive__container">
                <div className="header__wrapper">
                    <div className="header__row">
                        <div className="header__logotype font__title">
                            <Link to="/">A.CHABE</Link>
                        </div>
                        <div
                            className="header__toggle font__title font__yellow"
                            onClick={() => handleClick()}
                            ref={button}
                        >o</div>
                        <ul className="header__nav">
                            <li className="header__nav__el font__text">
                                <Link to="/">home</Link>
                            </li>
                            <li className="header__nav__el font__text">
                                <Link to="/about">about</Link>
                            </li>
                            <li className="header__nav__el font__text">
                                <Link to="/contact">contact</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="header__mobile">
                        <Advert
                            title="You found the coupon menu"
                            subtitle="you won... nothing!"
                            image={menuImage}
                        />
                        <ul className="header__mobile__nav">
                            <li className="header__mobile__nav__el font__text font__white">
                                <Link to="/">home</Link>
                            </li>
                            <li className="header__mobile__nav__el font__text font__white">
                                <Link to="/about">about</Link>
                            </li>
                            <li className="header__mobile__nav__el font__text font__white">
                                <Link to="/contact">contact</Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <svg className="header__coupon" width="1440" height="9" viewBox="0 0 1440 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 -0.000328064L8.34615 8.34583L16.6923 -0.000328064L25.0385 8.34583L33.3846 -0.000328064L41.7308 8.34583L50.0769 -0.000328064L58.4231 8.34583L66.7692 -0.000328064L75.1154 8.34583L83.4615 -0.000328064L91.8077 8.34583L100.154 -0.000328064L108.5 8.34583L116.846 -0.000328064L125.192 8.34583L133.538 -0.000328064L141.885 8.34583L150.231 -0.000328064L158.577 8.34583L166.923 -0.000328064L175.269 8.34583L183.615 -0.000328064L191.962 8.34583L200.308 -0.000328064L208.654 8.34583L217 -0.000328064L225.346 8.34583L233.692 -0.000328064L242.038 8.34583L250.385 -0.000328064L258.731 8.34583L267.077 -0.000328064L275.423 8.34583L283.769 -0.000328064L292.115 8.34583L300.462 -0.000328064L308.808 8.34583L317.154 -0.000328064L325.5 8.34583L333.846 -0.000328064H317.154H300.462H283.769H267.077H250.385H233.692H217H200.308H183.615H166.923H150.231H133.538H116.846H100.154H83.4615H66.7692H50.0769H33.3846H16.6923H0Z" fill="black"/>
                    <path d="M334 -0.000328064L342.346 8.34583L350.692 -0.000328064L359.038 8.34583L367.385 -0.000328064L375.731 8.34583L384.077 -0.000328064L392.423 8.34583L400.769 -0.000328064L409.115 8.34583L417.462 -0.000328064L425.808 8.34583L434.154 -0.000328064L442.5 8.34583L450.846 -0.000328064L459.192 8.34583L467.538 -0.000328064L475.885 8.34583L484.231 -0.000328064L492.577 8.34583L500.923 -0.000328064L509.269 8.34583L517.615 -0.000328064L525.962 8.34583L534.308 -0.000328064L542.654 8.34583L551 -0.000328064L559.346 8.34583L567.692 -0.000328064L576.038 8.34583L584.385 -0.000328064L592.731 8.34583L601.077 -0.000328064L609.423 8.34583L617.769 -0.000328064L626.115 8.34583L634.462 -0.000328064L642.808 8.34583L651.154 -0.000328064L659.5 8.34583L667.846 -0.000328064H651.154H634.462H617.769H601.077H584.385H567.692H551H534.308H517.615H500.923H484.231H467.538H450.846H434.154H417.462H400.769H384.077H367.385H350.692H334Z" fill="black"/>
                    <path d="M668.346 -0.000328064L676.692 8.34583L685.038 -0.000328064L693.385 8.34583L701.731 -0.000328064L710.077 8.34583L718.423 -0.000328064L726.769 8.34583L735.115 -0.000328064L743.462 8.34583L751.808 -0.000328064L760.154 8.34583L768.5 -0.000328064L776.846 8.34583L785.192 -0.000328064L793.538 8.34583L801.885 -0.000328064L810.231 8.34583L818.577 -0.000328064L826.923 8.34583L835.269 -0.000328064L843.615 8.34583L851.961 -0.000328064L860.308 8.34583L868.654 -0.000328064L877 8.34583L885.346 -0.000328064L893.692 8.34583L902.038 -0.000328064L910.385 8.34583L918.731 -0.000328064L927.077 8.34583L935.423 -0.000328064L943.769 8.34583L952.115 -0.000328064L960.462 8.34583L968.808 -0.000328064L977.154 8.34583L985.5 -0.000328064L993.846 8.34583L1002.19 -0.000328064H985.5H968.808H952.115H935.423H918.731H902.038H885.346H868.654H851.961H835.269H818.577H801.885H785.192H768.5H751.808H735.115H718.423H701.731H685.038H668.346Z" fill="black"/>
                    <path d="M1002.35 -0.000328064L1010.69 8.34583L1019.04 -0.000328064L1027.38 8.34583L1035.73 -0.000328064L1044.08 8.34583L1052.42 -0.000328064L1060.77 8.34583L1069.12 -0.000328064L1077.46 8.34583L1085.81 -0.000328064L1094.15 8.34583L1102.5 -0.000328064L1110.85 8.34583L1119.19 -0.000328064L1127.54 8.34583L1135.88 -0.000328064L1144.23 8.34583L1152.58 -0.000328064L1160.92 8.34583L1169.27 -0.000328064L1177.62 8.34583L1185.96 -0.000328064L1194.31 8.34583L1202.65 -0.000328064L1211 8.34583L1219.35 -0.000328064L1227.69 8.34583L1236.04 -0.000328064L1244.38 8.34583L1252.73 -0.000328064L1261.08 8.34583L1269.42 -0.000328064L1277.77 8.34583L1286.12 -0.000328064L1294.46 8.34583L1302.81 -0.000328064L1311.15 8.34583L1319.5 -0.000328064L1327.85 8.34583L1336.19 -0.000328064H1319.5H1302.81H1286.12H1269.42H1252.73H1236.04H1219.35H1202.65H1185.96H1169.27H1152.58H1135.88H1119.19H1102.5H1085.81H1069.12H1052.42H1035.73H1019.04H1002.35Z" fill="black"/>
                    <path d="M1336.5 3.8147e-05L1344.85 8.34619L1353.19 3.8147e-05L1361.54 8.34619L1369.88 3.8147e-05L1378.23 8.34619L1386.58 3.8147e-05L1394.92 8.34619L1403.27 3.8147e-05L1411.62 8.34619L1419.96 3.8147e-05L1428.31 8.34619L1436.65 3.8147e-05L1445 8.34619L1453.35 3.8147e-05L1461.69 8.34619L1470.04 3.8147e-05L1478.38 8.34619L1486.73 3.8147e-05L1495.08 8.34619L1503.42 3.8147e-05L1511.77 8.34619L1520.12 3.8147e-05L1528.46 8.34619L1536.81 3.8147e-05L1545.15 8.34619L1553.5 3.8147e-05L1561.85 8.34619L1570.19 3.8147e-05L1578.54 8.34619L1586.88 3.8147e-05L1595.23 8.34619L1603.58 3.8147e-05L1611.92 8.34619L1620.27 3.8147e-05L1628.62 8.34619L1636.96 3.8147e-05L1645.31 8.34619L1653.65 3.8147e-05L1662 8.34619L1670.35 3.8147e-05H1653.65H1636.96H1620.27H1603.58H1586.88H1570.19H1553.5H1536.81H1520.12H1503.42H1486.73H1470.04H1453.35H1436.65H1419.96H1403.27H1386.58H1369.88H1353.19H1336.5Z" fill="black"/>
                    <path d="M1670.5 3.8147e-05L1678.85 8.34619L1687.19 3.8147e-05L1695.54 8.34619L1703.88 3.8147e-05L1712.23 8.34619L1720.58 3.8147e-05L1728.92 8.34619L1737.27 3.8147e-05L1745.62 8.34619L1753.96 3.8147e-05L1762.31 8.34619L1770.65 3.8147e-05L1779 8.34619L1787.35 3.8147e-05L1795.69 8.34619L1804.04 3.8147e-05L1812.38 8.34619L1820.73 3.8147e-05L1829.08 8.34619L1837.42 3.8147e-05L1845.77 8.34619L1854.12 3.8147e-05L1862.46 8.34619L1870.81 3.8147e-05L1879.15 8.34619L1887.5 3.8147e-05L1895.85 8.34619L1904.19 3.8147e-05L1912.54 8.34619L1920.88 3.8147e-05L1929.23 8.34619L1937.58 3.8147e-05L1945.92 8.34619L1954.27 3.8147e-05L1962.62 8.34619L1970.96 3.8147e-05L1979.31 8.34619L1987.65 3.8147e-05L1996 8.34619L2004.35 3.8147e-05H1987.65H1970.96H1954.27H1937.58H1920.88H1904.19H1887.5H1870.81H1854.12H1837.42H1820.73H1804.04H1787.35H1770.65H1753.96H1737.27H1720.58H1703.88H1687.19H1670.5Z" fill="black"/>
                    <path d="M2004.85 3.8147e-05L2013.19 8.34619L2021.54 3.8147e-05L2029.88 8.34619L2038.23 3.8147e-05L2046.58 8.34619L2054.92 3.8147e-05L2063.27 8.34619L2071.62 3.8147e-05L2079.96 8.34619L2088.31 3.8147e-05L2096.65 8.34619L2105 3.8147e-05L2113.35 8.34619L2121.69 3.8147e-05L2130.04 8.34619L2138.38 3.8147e-05L2146.73 8.34619L2155.08 3.8147e-05L2163.42 8.34619L2171.77 3.8147e-05L2180.12 8.34619L2188.46 3.8147e-05L2196.81 8.34619L2205.15 3.8147e-05L2213.5 8.34619L2221.85 3.8147e-05L2230.19 8.34619L2238.54 3.8147e-05L2246.88 8.34619L2255.23 3.8147e-05L2263.58 8.34619L2271.92 3.8147e-05L2280.27 8.34619L2288.62 3.8147e-05L2296.96 8.34619L2305.31 3.8147e-05L2313.65 8.34619L2322 3.8147e-05L2330.35 8.34619L2338.69 3.8147e-05H2322H2305.31H2288.62H2271.92H2255.23H2238.54H2221.85H2205.15H2188.46H2171.77H2155.08H2138.38H2121.69H2105H2088.31H2071.62H2054.92H2038.23H2021.54H2004.85Z" fill="black"/>
                    <path d="M2338.85 3.8147e-05L2347.19 8.34619L2355.54 3.8147e-05L2363.88 8.34619L2372.23 3.8147e-05L2380.58 8.34619L2388.92 3.8147e-05L2397.27 8.34619L2405.62 3.8147e-05L2413.96 8.34619L2422.31 3.8147e-05L2430.65 8.34619L2439 3.8147e-05L2447.35 8.34619L2455.69 3.8147e-05L2464.04 8.34619L2472.38 3.8147e-05L2480.73 8.34619L2489.08 3.8147e-05L2497.42 8.34619L2505.77 3.8147e-05L2514.12 8.34619L2522.46 3.8147e-05L2530.81 8.34619L2539.15 3.8147e-05L2547.5 8.34619L2555.85 3.8147e-05L2564.19 8.34619L2572.54 3.8147e-05L2580.88 8.34619L2589.23 3.8147e-05L2597.58 8.34619L2605.92 3.8147e-05L2614.27 8.34619L2622.62 3.8147e-05L2630.96 8.34619L2639.31 3.8147e-05L2647.65 8.34619L2656 3.8147e-05L2664.35 8.34619L2672.69 3.8147e-05H2656H2639.31H2622.62H2605.92H2589.23H2572.54H2555.85H2539.15H2522.46H2505.77H2489.08H2472.38H2455.69H2439H2422.31H2405.62H2388.92H2372.23H2355.54H2338.85Z" fill="black"/>
                </svg>
            </div>
        </div>
    )
}
