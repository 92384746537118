import React from 'react'
import { RichText } from 'prismic-reactjs'

export const ColumnParagraph = (props) => {
    const borderTop = props.doc.primary.border_top
    const borderBot = props.doc.primary.border_bot

    return (
        <div
            className={`
                project__column-paragraph responsive__container border
                ${borderTop ? 'border-top' : ''}
                ${borderBot ? 'border-bot' : ''}
            `}
        >
            <div className="project__video__text font__text">
                {RichText.asText(props.doc.primary.column_paragraph_text)}
            </div>
        </div>
    )
}