import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { apiEndpoint } from './prismic-configuration'
import { Home, About, Contact, Project, NotFound } from './pages'
import { handleLoader, Grain } from './scripts'

/**
 * Main application componenet
 */
class App extends React.Component {
    constructor(props) {
        super(props)
        this.repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(apiEndpoint)
        this.repoName = this.repoNameArray[1]
    }

    componentDidMount() {
        new Grain(document.querySelector('.grain'))
        setTimeout(() => {
            // physicsLetters()
        }, 3000)
        // handleLoader()
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    {/* <script async defer src = {`//static.cdn.prismic.io/prismic.js?repo=${this.repoName}&new=true`}/> */}
                </Helmet>
                <BrowserRouter>
                    <Switch>
                        <Route exact path = "/" component = { Home }/>
                        <Route exact path = "/about" component = { About }/>
                        <Route exact path = "/contact" component = { Contact }/>
                        <Route exact path = "/projects/:uid" component = { Project }/>
                        <Route component = { NotFound }/> 
                    </Switch>
                </BrowserRouter>
            </Fragment>
        )
    }
}

export default App