import React, { useEffect, useState } from 'react'
import Prismic from 'prismic-javascript'
import { client } from '../../prismic-configuration'
import { RichText } from 'prismic-reactjs'

// COMPONENTS
import { ProjectCard } from './ProjectCard'

export const ProjectCards = () => {
    const [doc, setDocData] = useState(null)
    const [notFound, setNotFound] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            const result = await client.query(
                Prismic.Predicates.at('document.type', 'project'),
                { orderings: '[document.first_publication_date]' }
            )

            if (result) {
                result.results.sort((a, b) => {
                    const dateA = new Date(a.data.date)
                    const dateB = new Date(b.data.date)
                    return dateB - dateA
                })
                setDocData(result)
                setNotFound(false)
            } else {
                setNotFound(true)
                console.log('Could not find page or document.')
            }
        }
        fetchData()
    }, [])

    if (!notFound) {
        return <Cards doc={doc} />
    } else {
        return <></>
    }
}

const Cards = (props) => {
    const doc = props.doc
    const sortedCards = []
    const cards = []

    for (let i = 0; i < doc.results.length; i++) {
        cards.push(
            <ProjectCard
                index={i}
                title={RichText.asText(doc.results[i].data.title)}
                date={doc.results[i].data.date.split('-')[0]}
                roles={doc.results[i].data.roles}
                link={doc.results[i].data.link.url}
                key={`card--${i}`}
                letters={doc.results[i].data.letters}
            />
        )
    }

    return cards
}
