import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { client } from '../prismic-configuration'
import { RichText } from 'prismic-reactjs'

// ASSETS
import cursorImage from '../assets/images/Cursor-Right.png'

// COMPONENTS
import {
    Header,
    Footer,
    Eye,
    ProjectCards,
    Advert,
    Loader,
} from '../components'
import { NotFound } from './NotFound'

export const Home = () => {
    const [doc, setDocData] = useState(null)
    const [notFound, setNotFound] = useState(true)
    const uid = 'home'

    useEffect(() => {
        const fetchData = async () => {
            const result = await client.getByUID(uid, uid)

            if (result) {
                setDocData(result)
                setNotFound(false)
            } else {
                setNotFound(true)
                console.log('Could not find page or document.')
            }
        }
        fetchData()
    }, [uid])

    if (!notFound) {
        return (
            <div className="home responsive">
                <Header />
                <Eye />
                <div className="home__projects responsive__container">
                    <Advert
                        title="Creative people are always ..."
                        subtitle="the coolest kids in the room"
                        image={cursorImage}
                    />
                    <ProjectCards />
                    <Advert
                        title="Check out my free packages !"
                        subtitle="No discount code needed !"
                        image={cursorImage}
                        link="https://www.npmjs.com/~saucy_"
                    />
                </div>
                <Footer />
            </div>
        )
    } else {
        return <Loader />
    }
}
