import React, { useEffect } from 'react'
import { RichText } from 'prismic-reactjs'
import Matter from 'matter-js'
import { letters } from '../../scripts/assetsLoader'

export const ProjectCard = (props) => {
    useEffect(() => {
        Letters(canvasRef.current, props.letters)
    }, [])

    let canvasRef = React.useRef()

    return (
        <div className="project-card">
            <canvas
                className={`project-card__letters project-card__letters--${props.index}`}
                letters={RichText.asText(props.letters)}
                ref={canvasRef}
            ></canvas>
            <h3 className="project-card__title font__title font__wdif usn pen">{props.title}</h3>
            <div className="project-card__date font__subtitle font__wdif usn pen">{props.date}</div>
            <div className="project-card__foot usn pen">
                <a className="project-card__arrow pea" href={props.link} target="_blank"><span>👉</span></a>
            </div>
            {Roles(props.roles)}
        </div>
    )
}

const Roles = (_roles) => {
    const roles = []
    for (let i = 0; i < _roles.length; i++) {
        roles.push(
            <span
                className={`project-card__role project-card__role--${i} font__text font__wdif usn pen`}
                key={`project-card__role--${i}`}
            >
                {RichText.asText(_roles[i].role)}
            </span>
        )
    }
    return roles
}

const Letters = (_canvas, _letters) => {
    // ENGINE
    const container = _canvas
    const engine = Matter.Engine.create()
    const render = Matter.Render.create({
        canvas: container,
        engine: engine,
        options: {
            wireframes: false,
            background: 'transparent',
            wireframeBackground: 'transparent',
            width: 928,
            height: 316,
        },
    })
    engine.world.gravity.x = 0
    engine.world.gravity.y = 0
    engine.world.bounds = { 
        min: { 
            x: 0, 
            y: 0 
        }, 
        max: { 
            x: 928, 
            y: 316
        } 
    }

    // MOUSE
    const mouse = Matter.Mouse.create(render.canvas)
    const mouseConstraint = Matter.MouseConstraint.create(
        engine, {
            mouse: mouse,
            constraint: {
                render: {
                    visible: false,
                },
            },
        }
    )
    // Prevent canvas to stop scroll event
    mouseConstraint.mouse.element.removeEventListener('mousewheel', mouseConstraint.mouse.mousewheel)
    mouseConstraint.mouse.element.removeEventListener('DOMMouseScroll', mouseConstraint.mouse.mousewheel)
    // mouseConstraint.mouse.element.removeEventListener('touchmove', mouseConstraint.mouse.mousemove)
    // mouseConstraint.mouse.element.removeEventListener('touchstart', mouseConstraint.mouse.mousedown)
    // mouseConstraint.mouse.element.removeEventListener('touchend', mouseConstraint.mouse.mouseup)
    render.mouse = mouse

    // OBJECTS
    const rightWallProps = {
        w: 100,
        h: 500,
        posX: container.offsetWidth,
        posY: 500 / 2,
    }
    const groundProps = {
        w: 1000,
        h: 100,
        posX: 928 / 2,
        posY: container.offsetHeight + (20 / 2)
    }
    const boundingBoxesProps = {rightWall: rightWallProps, ground: groundProps}
    const boundingBoxes = createBoundingBoxes(container, boundingBoxesProps)

    const lettersAttribute = container.getAttribute('letters')
    const lettersStrings = lettersAttribute.split('')
    const lettersBodies = createLetter(lettersStrings, container)

    const entities = [...boundingBoxes, ...lettersBodies, mouseConstraint]

    // EVENTS
    window.addEventListener('resize', () => {
        resizeReset(render, container, lettersBodies, boundingBoxes, boundingBoxesProps)
    })

    // RUN
    resizeReset(render, container, lettersBodies, boundingBoxes, boundingBoxesProps)
    Matter.World.add(engine.world, entities)
    Matter.Render.run(render)
    Matter.Engine.run(engine)
}

const randBetween = (min, max) => {
    return Math.floor(Math.random()*(max-min+1)+min)
}

// CREATE BODIES
const createLetter = (_letters, _container) => {
    const lettersBodies = []

    for (let i = _letters.length - 1; i >= 0; i--) {
        const currentLetter = letters[`letter${_letters[i]}`]
        const scaleMultiplicator = 1

        let letter
        if(_letters[i] === "O") {
            letter = Matter.Bodies.circle(
                (_container.offsetWidth / 2) - 100 + (i + 1) * 66,
                _container.offsetHeight / 2,
                currentLetter.h / 2 * scaleMultiplicator, 
                {
                    render: {
                        sprite: {
                            texture: currentLetter.img,
                            xScale: 1 * scaleMultiplicator,
                            yScale: 1 * scaleMultiplicator,
                        },
                    },
                }
            )
        }
        else {
            letter = Matter.Bodies.rectangle(
                (_container.offsetWidth / 2) - 100 + (i + 1) * 66,
                _container.offsetHeight / 2,
                currentLetter.w * scaleMultiplicator,
                currentLetter.h * scaleMultiplicator, 
                {
                    render: {
                        sprite: {
                            texture: currentLetter.img,
                            xScale: 1 * scaleMultiplicator,
                            yScale: 1 * scaleMultiplicator,
                        },
                    },
                    // force: {x:1, y:1},
                    friction: 0,
                    frictionAir: 0,
                    frictionStatic: 0,
                    slop: 1
                }
            )
        }
        // Matter.Body.setMass(letter, 10)
        // Matter.Body.setVelocity(letter, 0.01)
        Matter.Body.applyForce(
            letter,
            letter.position, 
            Matter.Vector.create(randBetween(-1, 1), randBetween(-1, 1))
        )
        lettersBodies.push(letter)
    }

    return lettersBodies
}

const createBoundingBoxes = (_container, _props) => {
    const ceiling = Matter.Bodies.rectangle(928 / 2, -50, 928, 100, {
        isStatic: true,
        render: {
            fillStyle: 'transparent',
            strokeStyle: 'transparent',
        },
    })

    const leftWall = Matter.Bodies.rectangle(-50, 250, 100, 500, {
        isStatic: true,
        render: {
            fillStyle: 'transparent',
            strokeStyle: 'transparent',
        },
    })

    const rightWallProps = _props.rightWall
    const rightWall = Matter.Bodies.rectangle(
        rightWallProps.posX,
        rightWallProps.posY,
        rightWallProps.w,
        rightWallProps.h, {
            isStatic: true,
            render: {
                fillStyle: 'blue',
                strokeStyle: 'transparent',
            },
        }
    )

    const groundProps = _props.ground
    const ground = Matter.Bodies.rectangle(
        groundProps.posX,
        groundProps.posY,
        groundProps.w,
        groundProps.h, {
            isStatic: true,
            render: {
                fillStyle: 'transparent',
                strokeStyle: 'transparent',
            },
        }
    )

    const boundingBoxes = [
        ceiling,
        rightWall,
        leftWall,
        ground
    ]

    return boundingBoxes
}

// RESPONSIVE
const resizeReset = (_render, _container, _letters, _boundingBoxes, _boundingBoxesProps) => {
    _render.bounds.max.x = _container.getBoundingClientRect().width
    _render.bounds.max.y = _container.getBoundingClientRect().height
    _render.options.width = _container.getBoundingClientRect().width
    _render.options.height = _container.getBoundingClientRect().height
    _render.canvas.width = _container.getBoundingClientRect().width
    _render.canvas.height = _container.getBoundingClientRect().height

    // SCALE
    // const scale = window.innerWidth > 480 ? 1.5 : 1
    // for(let i=0; i<_letters.length; i++) {
    //     Matter.Body.scale(_letters[i], scale, scale)
    // }

    // GROUND
    Matter.Body.setPosition(
        _boundingBoxes[3],
        Matter.Vector.create(
            _container.offsetWidth / 2,
            _container.offsetHeight + _boundingBoxesProps.ground.h / 2
        )
    )

    // RIGHT WALL
    Matter.Body.setPosition(
        _boundingBoxes[1],
        Matter.Vector.create(
            _container.offsetWidth + _boundingBoxesProps.rightWall.w / 2,
            _boundingBoxesProps.rightWall.posY
        )
    )
}