import React from 'react'
import { RichText } from 'prismic-reactjs'

export const Advert = (props) => {

    return (
        props.link ? 
        <a href={props.link} target="_blank" className="advert">
            <h3 className="advert__row advert__row--left">
                <span className="advert__title font__title">{props.title}</span>
                <span className="advert__subtitle font__subtitle">{props.subtitle}</span>
            </h3>
            <div className="advert__row advert__row--right">
                <img src={props.image} className="advert__image" />
            </div>
        </a>
        :
        <div className="advert">
            <h3 className="advert__row advert__row--left">
                <span className="advert__title font__title">{props.title}</span>
                <span className="advert__subtitle font__subtitle">{props.subtitle}</span>
            </h3>
            <div className="advert__row advert__row--right">
                <img src={props.image} className="advert__image" />
            </div>
        </div>
    )
}