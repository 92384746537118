// LETTERS
import Aimg from '../assets/images/A.png'
import Bimg from '../assets/images/B.png'
import Cimg from '../assets/images/C.png'
import Dimg from '../assets/images/D.png'
import Eimg from '../assets/images/E.png'
import Fimg from '../assets/images/F.png'
import Gimg from '../assets/images/G.png'
import Himg from '../assets/images/H.png'
import Iimg from '../assets/images/I.png'
import Jimg from '../assets/images/J.png'
import Kimg from '../assets/images/K.png'
import Limg from '../assets/images/L.png'
import Mimg from '../assets/images/M.png'
import Nimg from '../assets/images/N.png'
import Oimg from '../assets/images/O.png'
import Pimg from '../assets/images/P.png'
import Qimg from '../assets/images/Q.png'
import Rimg from '../assets/images/R.png'
import Simg from '../assets/images/S.png'
import Timg from '../assets/images/T.png'
import Uimg from '../assets/images/U.png'
import Vimg from '../assets/images/V.png'
import Wimg from '../assets/images/W.png'
import Ximg from '../assets/images/X.png'
import Yimg from '../assets/images/Y.png'
import Zimg from '../assets/images/Z.png'

const letterA = {
    img: Aimg,
    w: 92,
    h: 92
}

const letterB = {
    img: Bimg,
    w: 75,
    h: 90
}

const letterC = {
    img: Cimg,
    w: 69,
    h: 94
}

const letterD = {
    img: Dimg,
    w: 87,
    h: 90
}

const letterE = {
    img: Eimg,
    w: 61,
    h: 90
}

const letterF = {
    img: Fimg,
    w: 61,
    h: 90
}

const letterG = {
    img: Gimg,
    w: 96,
    h: 94
}

const letterH = {
    img: Himg,
    w: 76,
    h: 90
}

const letterI = {
    img: Iimg,
    w: 32,
    h: 90
}

const letterJ = {
    img: Jimg,
    w: 67,
    h: 92
}

const letterK = {
    img: Kimg,
    w: 83,
    h: 90
}

const letterL = {
    img: Limg,
    w: 63,
    h: 90
}

const letterM = {
    img: Mimg,
    w: 102,
    h: 90
}

const letterN = {
    img: Nimg,
    w: 78,
    h: 95
}

const letterO = {
    img: Oimg,
    w: 96,
    h: 94
}

const letterP = {
    img: Pimg,
    w: 75,
    h: 90
}

const letterQ = {
    img: Qimg,
    w: 96,
    h: 112
}

const letterR = {
    img: Rimg,
    w: 75,
    h: 90
}

const letterS = {
    img: Simg,
    w: 70,
    h: 90
}

const letterT = {
    img: Timg,
    w: 68,
    h: 90
}

const letterU = {
    img: Uimg,
    w: 70,
    h: 92
}

const letterV = {
    img: Vimg,
    w: 90,
    h: 87
}

const letterW = {
    img: Wimg,
    w: 118,
    h: 93
}

const letterX = {
    img: Ximg,
    w: 86,
    h: 90
}

const letterY = {
    img: Yimg,
    w: 92,
    h: 90
}

const letterZ = {
    img: Zimg,
    w: 77,
    h: 90
}

export const letters = {letterA, letterB, letterC, letterD, letterE, letterF, letterG, letterH, letterI, letterJ, letterK, letterL, letterM, letterN, letterO, letterP, letterQ, letterR, letterS, letterT, letterU, letterV, letterW, letterX, letterY, letterZ}